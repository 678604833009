<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card class="py-5">
      <v-card-title>
        {{ this.$route.params.id ? 'تعديل' : 'اضافة' }}
      </v-card-title>
      <template>
        <v-form
          @submit.prevent="submitForm()"
        >
          <v-container fluid>
            <v-row class="mx-md-16 px-md-16">
              <v-col
                cols="12"
                md="6"
              >
                <!-- <label
                  class="d-block mb-3 font-weight-bold"
                >المعايير المهنية</label> -->
                <v-textarea
                  v-model="data.trans_professional_standard"
                  label="المعايير المهنية"
                  outlined
                  required
                  :disabled="dataLoading"
                />
              </v-col>
            </v-row>
            <v-row class="mx-md-16 px-md-16">
              <v-col
                cols="12"
                md="6"
              >
                <!-- <label
                  class="d-block mb-3 font-weight-bold"
                >اقرار بالاستقلالية وعدم تضارب المصالح</label> -->
                <v-textarea
                  v-model="data.acknowledgment_independence"
                  label="اقرار بالاستقلالية وعدم تضارب المصالح"
                  outlined
                  required
                  :disabled="dataLoading"
                />
              </v-col>
            </v-row>
            <v-row class="mx-md-16 px-md-16">
              <v-col
                cols="12"
                md="6"
              >
                <!-- <label
                  class="d-block mb-3 font-weight-bold"
                >القيود على الاستخدام والنشر</label> -->
                <v-textarea
                  v-model="data.trans_restrictions_publication"
                  label="القيود على الاستخدام والنشر"
                  outlined
                  required
                  :disabled="dataLoading"
                />
              </v-col>
            </v-row>
            <v-row class="mx-md-16 px-md-16">
              <v-col
                cols="12"
                md="6"
              >
                <!-- <label
                  class="d-block mb-3 font-weight-bold"
                >إخلاء المسؤولية</label> -->
                <v-textarea
                  v-model="data.trans_evacuation_responsibility"
                  outlined
                  required
                  label="إخلاء المسؤولية"
                  :disabled="dataLoading"
                />
              </v-col>
            </v-row>
            <v-row class="mx-md-16 px-md-16">
              <v-col
                cols="12"
                md="6"
              >
                <!-- <label
                  class="d-block mb-3 font-weight-bold"
                >الافتراضات الخاصة ان وجدت</label> -->
                <v-textarea
                  v-model="data.trans_special_assumptions"
                  label="الافتراضات الخاصة ان وجدت"
                  outlined
                  required
                  :disabled="dataLoading"
                />
              </v-col>
            </v-row>

            <v-btn
              type="submit"
              class="mx-auto my-auto d-flex"
              color="indigo"
              :loading="dataLoading"
            >
              {{ this.$route.params.id ? 'حفظ' : 'اضافة' }}
            </v-btn>
          </v-container>
        </v-form>
      </template>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      bottom
      left
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      left
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import { ServiceFactory } from '../../../../services/ServiceFactory'
  const facilityStandards = ServiceFactory.get('facilityStandards')

  export default {
    name: 'FacilityStandards',
    data: () => ({
      permissions: {},
      dataLoading: false,
      regionsLoading: false,
      valid: false,
      will: '',
      data: {
        id: '',
        trans_professional_standard: '',
        acknowledgment_independence: '',
        trans_restrictions_publication: '',
        trans_evacuation_responsibility: '',
        trans_special_assumptions: '',
      },
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      loading: false,
      disabled: false,
    }),
    created () {
      this.getAll()
    },
    methods: {
      async getAll () {
        this.dataLoading = true
        try {
          const response = await facilityStandards.getOne()
          // if (Object.keys(response.data).length === 1) {
          //   throw new Error(response.data.Message)
          // }
          const { data } = response.data
          // if (data.id) {
          //   this.will = 'update'
          // } else {
          //   this.will = 'create'
          // }
          this.data = data
        } catch (err) {
          // console.log({err})
          this.errorMessage = err.message
          this.errorSnackbar = true
        } finally {
          this.dataLoading = false
        }
        // if (data.length !== 0) {
        //   const content = data[0].Content
        //   const id = data[0].id
        //   this.data.content_en = content
        //   this.data.id = id
        //   this.will = 'update'
        // } else {
        //   this.will = 'create'
        // }
      },
      async updateOne () {
        this.dataLoading = true
        try {
          const response = await facilityStandards.update(this.data.id, this.data)
          const data = response.data
          if (data.errors) {
            throw new Error('حدثت مشكلة')
          }
          this.successSnackbar = true
          this.successMessage = 'تم التعديل بنجاح'
        } catch (err) {
          this.errorSnackbar = true
          console.log({ err })
          this.errorMessage = err.message
        } finally {
          this.dataLoading = false
        }
      },
      async createOne () {
        this.dataLoading = true
        try {
          const response = await facilityStandards.create(this.data)
          const data = response.data
          if (data.errors) {
            throw new Error('حدثت مشكلة')
          }
          this.successSnackbar = true
          this.successMessage = 'تم التعديل بنجاح'
          // this.will = 'update'
        } catch (err) {
          this.errorSnackbar = true
          this.errorMessage = err.message
        } finally {
          this.dataLoading = false
        }
      },
      async deleteOne () {
        this.dataLoading = true
        try {
          const response = await facilityStandards.deleteOne(this.data.id)
          const data = response.data
          if (data.errors) {
            throw new Error('حدثت مشكلة')
          }
          this.successSnackbar = true
          this.successMessage = 'تم التعديل بنجاح'
          this.will = 'create'
        } catch (err) {
          this.errorSnackbar = true
          this.errorMessage = 'حدثت مشكلة'
        } finally {
          this.dataLoading = false
        }
      },
      submitForm () {
        // if (this.will === 'create') {
        this.createOne()
        // } else {
        //   this.updateOne()
        // }
      },
    },
  }
</script>
